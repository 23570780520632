export const customMessages = {
    required: 'Required',
    alpha_dash: 'only alphabets, numbers and spaces are allowed',
    regex: 'Invalid value, only alphabets, numbers, hypen, spaces are allowed',
    email: 'Invalid Email'
};

export const passwordValidationMessage = {
    regex: 'The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (!@#\$%\^&\*)',
    required: 'Required',
    alpha_dash: 'only alphabets, numbers and spaces are allowed',
    email: 'Invalid Email'
}
